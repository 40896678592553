import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloClient, InMemoryCache, ApolloProvider, /* createHttpLink */ } from '@apollo/client';
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';
import { store, persistor } from './actions/store';
import { APIENDPOINT } from './config';

// const link = createHttpLink({
//   uri: `${APIENDPOINT}/graphql`,
//   credentials: 'include'
// });

const link = createUploadLink({
  uri: `${APIENDPOINT}/graphql`,
  credentials: 'include'
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <HelmetProvider>
              <Suspense fallback={<div />}>
                <App />
              </Suspense>
            </HelmetProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>
);
