// import { toast } from 'react-hot-toast';
import { APIENDPOINT, axiosInstance } from '../config';
import { store } from './store';
import { logout } from './appSlice';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const handleError = (status) => {

  switch(status) {
    case 401:
      const logOut = async() => {
        try {
          await axiosInstance({
            url:`${APIENDPOINT}/logout`,
            method: 'POST'
          });
        } catch(e) {
          console.log(e);
        } finally {
          store.dispatch(logout())
        }
      };

      logOut();
      break;
    default:
      break;
  }
};