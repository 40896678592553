import React, { useState, useEffect } from 'react';
import { NavLink, useRoutes, Outlet } from 'react-router-dom';
import { Navigate, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Toaster, toast } from 'react-hot-toast';
import { Popover, PopoverBody } from 'reactstrap';
import Avatar from 'react-avatar';
// CHECK FOR EXPIRED COOKIE SESSION
import { useLazyQuery } from '@apollo/client';
// END OF CHECK FOR EXPIRED COOKIE SESSION

import { APIENDPOINT, ROLES, axiosInstance } from './config';
import { logout, collapseSidebar, expandSidebar } from './actions/appSlice';
import './App.scss';
import addIcons from './actions/addIcons';
import hamburger from './assets/img/hamburger.svg';
import logo from './assets/img/logo.png';
import logoAdmin from './assets/img/logo_admin.png';
import iconApp from './assets/img/icon-app.svg';
import iconDashboard from './assets/img/icon-dashboard-normal-active.svg';
// import iconManifest from './assets/img/icon-manifest_active.svg';
import iconReport from './assets/img/icon-report-normal-black_active.svg';
import iconSearch from './assets/img/icon-smol-search-black_active.svg';
import iconUpload from './assets/img/icon-upload_active.svg';
// import iconLink from './assets/img/icon-upload-from-url_active.svg';
import iconUser from './assets/img/icon-identity-mgt_active.svg';
import iconLink from './assets/img/icon-upload-from-url-admin_active.svg';
import networkIcon from './assets/img/network.svg';
import iconExpandNode from './assets/img/icon-expand-node.svg';
import iconChatBlack from './assets/img/icon-chat-black.svg';
import iconGuessNode from './assets/img/icon-guess-node.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-circular-progressbar/dist/styles.css';

// CHECK FOR EXPIRED COOKIE SESSION
import { GET_THREATLIST } from './views/DataExtraction/DataBrowser/Threats/Threats';
// import { handleError } from './actions/networkActions';
// END OF CHECK FOR EXPIRED COOKIE SESSION

addIcons();

const Login = React.lazy(() => import('./views/Login/Login'));
const Dashboard = React.lazy(() => import('./views/Dashboard/Dashboard'));
const Sources = React.lazy(() => import('./views/Sources/Sources'));
const Analysis = React.lazy(() => import('./views/Analysis/Analysis'));
const DataExtraction = React.lazy(() => import('./views/DataExtraction/DataExtraction'));
const PacketBuilderPrintable = React.lazy(() => import('./views/DataExtraction/PacketBuilder/PacketBuilderPrintable'));
const Governance = React.lazy(() => import('./views/Governance/Governance'));
const Admin = React.lazy(() => import('./views/Admin/Admin'));

const DndApp = React.lazy(() => import('./views/_Playground/DndApp'));

function Layout() {
  return (
    <>
      <Toaster position='top-right' />
      <Outlet />
    </>
  )
}

function AdminLayout() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const username = useSelector(state => state.app.username);
  const role = useSelector(state => state.app.role);

  const isSidebarOpen = useSelector(state => state.app.isSidebarOpen);
  const expandCollapseSidebar = () => {
    isSidebarOpen ? dispatch(collapseSidebar()) : dispatch(expandSidebar());
  };

  const [isUserPopoverOpen, setIsUserPopoverOpen] = useState(false);

  const logOut = async() => {
    try {
      await axiosInstance({
        url:`${APIENDPOINT}/logout`,
        method: 'POST'
      });
      toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
        <div>
          <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-vsepr" />
        </div>
        <div>
          <h6 className="fw-bold mb-0 text-vsepr">Successfully Logged Out</h6>
        </div>
      </div>);
    } catch(e) {
      console.log(e);
    } finally {
      dispatch(logout());
    }
  };

  const [offCanvasNavIsOpen, setOffCanvasIsOpen] = useState(false);

  function renderNav() {
    return (
      <nav className="nav flex-column mt-4">
        <li className="nav-item">
          <NavLink to="/" className={({ isActive }) => `btn btn-outline-vseprmenuactive rounded-0 text-start text-muted nav-link py-3 d-flex align-items-center ${isActive ? 'active bg-vseprmenuactive' : ''}`}>
            <img alt="" src={iconDashboard} className="me-2" />
            <span>Dashboard</span>
          </NavLink>
        </li>
        {role === ROLES.ADMIN &&
          <li className="nav-item">
            <span className="text-vseprdark nav-link fw-bold text-uppercase py-2">Sources</span>
          </li>
        }
        {/* <li className="nav-item">
          <NavLink to="/sources/sourceconfiguration" className={({ isActive }) => `btn btn-outline-vseprmenuactive rounded-0 text-start text-muted nav-link py-3 d-flex align-items-center ${isActive ? 'active bg-vseprmenuactive' : ''}`}>
            <img alt="" src={iconLink} className="me-2" />
            <span>Source Configuration</span>
          </NavLink>
        </li> */}
        {role === ROLES.ADMIN &&
          <li className="nav-item">
            <NavLink to="/sources/dataimport" className={({ isActive }) => `btn btn-outline-vseprmenuactive rounded-0 text-start text-muted nav-link py-3 d-flex align-items-center  ${isActive ? 'active bg-vseprmenuactive' : ''}`}>
              <img alt="" src={iconUpload} className="me-2" />
              <span>Data Import</span>
            </NavLink>
          </li>
        }
        <li className="nav-item">
          <span className="text-vseprdark nav-link fw-bold text-uppercase py-2">Analysis</span>
        </li>
        <li className="nav-item">
          <NavLink to="/analysis/datadiscovery" className={({ isActive }) => `btn btn-outline-vseprmenuactive rounded-0 text-start text-muted nav-link py-3 d-flex align-items-center ${isActive ? 'active bg-vseprmenuactive' : ''}`}>
            <img alt="" src={iconExpandNode} className="me-2" />
            <span>Data Discovery</span>
          </NavLink>
        </li>
        {role === ROLES.ADMIN &&
          <li className="nav-item">
            <NavLink to="/analysis/potentialrelationships" className={({ isActive }) => `btn btn-outline-vseprmenuactive rounded-0 text-start text-muted nav-link py-3 d-flex align-items-center ${isActive ? 'active bg-vseprmenuactive' : ''}`}>
              <img alt="" src={iconGuessNode} className="me-2" />
              <span>Potential Relationships</span>
            </NavLink>
          </li>
        }
        <li className="nav-item">
          <NavLink to="/analysis/brainstorm" className={({ isActive }) => `btn btn-outline-vseprmenuactive rounded-0 text-start text-muted nav-link py-3 d-flex align-items-center ${isActive ? 'active bg-vseprmenuactive' : ''}`}>
            <img alt="" src={iconChatBlack} className="me-2" />
            <span>Brainstorm</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <span className="text-vseprdark nav-link fw-bold text-uppercase py-2">Data Extraction</span>
        </li>
        <li className="nav-item">
          <NavLink to="/dataextraction/databrowser" className={({ isActive }) => `btn btn-outline-vseprmenuactive rounded-0 text-start text-muted nav-link py-3 d-flex align-items-center ${isActive ? 'active bg-vseprmenuactive' : ''}`}>
            <img alt="" src={iconSearch} className="me-2" />
            <span>Data Browser</span>
          </NavLink>
        </li>
        {role === ROLES.ADMIN &&
          <li className="nav-item">
            <NavLink to="/dataextraction/packets" className={({ isActive }) => `btn btn-outline-vseprmenuactive rounded-0 text-start text-muted nav-link py-3 d-flex align-items-center ${isActive ? 'active bg-vseprmenuactive' : ''}`}>
              <img alt="" src={iconReport} className="me-2" />
              <span>Packet Builder</span>
            </NavLink>
          </li>
        }
        {/* <li className="nav-item">
          <span className="text-vseprdark nav-link fw-bold text-uppercase py-2">Governance</span>
        </li> */}
        {/* <li className="nav-item">
          <NavLink to="/governance/extractionrequests" className={({ isActive }) => `btn btn-outline-vseprmenuactive rounded-0 text-start text-muted nav-link py-3 d-flex align-items-center ${isActive ? 'active bg-vseprmenuactive' : ''}`}>
            <img alt="" src={iconManifest} className="me-2" />
            <span>Extraction Requests</span>
          </NavLink>
        </li> */}
      </nav>
    )
  }

  // CHECK FOR EXPIRED COOKIE SESSION USING A TEST ENDPOINT
  const [loading, setLoading] = useState(true);
  const [
    getThreatList,
    {  /* data: dataGetThreatList,  loading: loadingGetThreatList, */ error: errorGetThreatList, /* refetch: refetchThreatList */ },
  ] = useLazyQuery(GET_THREATLIST, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    getThreatList({ variables: { filter: '', page_index: 0, limit: 1 } });
  }, [getThreatList]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);

    if (!errorGetThreatList) {
      return;
    }

    dispatch(logout());
    toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
      <div>
        <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-vsepr" />
      </div>
      <div>
        <h6 className="fw-bold mb-0 text-vsepr">You have been logged out due to inactivity.</h6>
      </div>
    </div>);
    navigate("/login");

    // handleError(errorGetThreatList.networkError?.response?.status);
  }, [errorGetThreatList, dispatch, navigate]);

  if (loading) {
    return <div />;
  }
  // END OF CHECK FOR EXPIRED COOKIE SESSION

  return (
    <div id="main">
      <nav className="bg-vseprlight d-flex" style={{ height: '75px' }}>
        <div className="container-fluid d-flex flex-grow-1">
          <div className="row no-gutters d-flex flex-grow-1 align-items-center">
            <div className="col d-flex align-items-center">
              <div className="d-none d-md-block">
                <button type="button" className="btn px-2" onClick={expandCollapseSidebar}><img alt="hamburger" src={hamburger} /></button>
              </div>
              <div className="d-md-none">
                <button type="button" className="btn px-2" onClick={() => { setOffCanvasIsOpen(!offCanvasNavIsOpen) }}><img alt="hamburger" src={hamburger} /></button>
              </div>
              <div className="d-inline-block px-3">
                <NavLink to="/" className="navbar-brand">
                  <img alt="Project VSEPR" src={logo} width="100" />
                </NavLink>
              </div>
            </div>
            <div className="col d-flex align-items-center justify-content-end">
              <div className="d-none d-md-block bg-white me-2">
                <button type="button" className="btn btn-sm btn-outline-vsepr px-2 py-1 rounded-1 btn-network">
                  <img alt="" src={networkIcon} className="me-2" />
                  HQ Network
                  
                  <FontAwesomeIcon icon="chevron-down" size="xs" className="ms-4" />
                </button>
              </div>

              <div className="d-none d-md-block">
                <button type="button" className="btn px-2">
                  <img alt="" src={iconApp} />
                </button>
              </div>

              <button type="button" id="userPopoverButton" className="btn">
                <Avatar name={username} size="30" round={true} />
                <FontAwesomeIcon icon="chevron-down" size="sm" className="ms-2" />
              </button>
              <Popover placement="bottom-end" isOpen={isUserPopoverOpen} target="userPopoverButton" toggle={() => { setIsUserPopoverOpen(!isUserPopoverOpen); }} trigger="legacy">
                <PopoverBody style={{ width: '280px' }}>
                  <div className="d-flex align-items-start">
                    <div className="me-2">
                      <Avatar name={username} size="45" round={true} />
                    </div>
                    <div>
                      <h6 className="mb-1">Hello {username}!</h6>
                      <p>Welcome to Project VSEPR</p>
                    </div>
                  </div>
                  
                  <div className="d-flex align-items-start">
                    {role === ROLES.ADMIN &&
                      <NavLink to="/admin" className="btn btn-sm btn-light me-2">Go to Admin</NavLink>
                    }
                    <button type="button" className="btn btn-sm btn-vsepr" onClick={logOut}>Logout</button>
                  </div>
                </PopoverBody>
              </Popover>
            </div>
          </div>
        </div>
      </nav>
      <div className="container-fluid d-flex" style={{ height: `calc(100vh - 75px)` }}>
        <div className="row no-gutters flex-grow-1 bg-vseprlight">
          {isSidebarOpen &&
            <div className="col-2 p-0 d-md-none d-lg-block">
              {renderNav()}
            </div>
          }
          <div className={`offcanvas offcanvas-end ${offCanvasNavIsOpen ? 'show' : ''} d-block d-sm-none`}>
            <div className="offcanvas-header">
              <button type="button" className="btn-close" onClick={() => { setOffCanvasIsOpen(!offCanvasNavIsOpen) }}></button>
            </div>
            <div className="offcanvas-body">
              {renderNav()}
            </div>
          </div>

          <div className="col bg-white p-0" style={{ borderRadius: isSidebarOpen ? '20px 0 0 0' : '0px' }}>
            <div className="main">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function SuperAdminLayout() {

  const dispatch = useDispatch()

  const username = useSelector(state => state.app.username);

  const [isUserPopoverOpen, setIsUserPopoverOpen] = useState(false);

  const logOut = async() => {
    try {
      await axiosInstance({
        url:`${APIENDPOINT}/logout`,
        method: 'POST'
      });
      toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
        <div>
          <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-vsepr" />
        </div>
        <div>
          <h6 className="fw-bold mb-0 text-vsepr">Successfully Logged Out</h6>
        </div>
      </div>);
    } catch(e) {
      console.log(e);
    } finally {
      dispatch(logout());
    }
  };

  return (
    <div id="main">
      <nav className="d-flex" style={{ height: '75px' }}>
        <div className="container-fluid d-flex flex-grow-1">
          <div className="row no-gutters d-flex flex-grow-1 align-items-center">
            <div className="bg-vseprdarker col-2 h-100 d-flex align-items-center">
              <div className="d-inline-block">
                <NavLink to="/admin" className="navbar-brand">
                  <img alt="Project VSEPR: Admin" src={logoAdmin} width="200" />
                </NavLink>
              </div>
            </div>
            <div className="col d-flex align-items-center justify-content-end h-100 bg-light">
              <div className="d-inline-block me-2">
                <button type="button" className="btn btn-sm btn-outline-secondary px-2 py-1 rounded-1 btn-network">
                  <img alt="" src={networkIcon} className="me-2" />
                  HQ Network

                  <FontAwesomeIcon icon="chevron-down" size="xs" className="ms-4" />
                </button>
              </div>

              <div className="d-inline-block">
                <button type="button" className="btn px-2">
                  <img alt="" src={iconApp} />
                </button>
              </div>

              <button type="button" id="userPopoverButton" className="btn">
                <Avatar name={username} size="30" round={true} />
                <FontAwesomeIcon icon="chevron-down" size="sm" className="ms-2" />
              </button>
              <Popover placement="bottom-end" isOpen={isUserPopoverOpen} target="userPopoverButton" toggle={() => { setIsUserPopoverOpen(!isUserPopoverOpen); }} trigger="legacy">
                <PopoverBody style={{ width: '280px' }}>
                  <div className="d-flex align-items-start">
                    <div className="me-2">
                      <Avatar name={username} size="45" round={true} />
                    </div>
                    <div>
                      <h6 className="mb-1">Hello {username}!</h6>
                      <p>Welcome to Project VSEPR</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-start">
                    <NavLink to="/" className="btn btn-sm btn-light me-2">Go to Portal</NavLink><br/>
                    <button type="button" className="btn btn-sm btn-vsepr" onClick={logOut}>Logout</button>
                  </div>
                </PopoverBody>
              </Popover>
            </div>
          </div>
        </div>
      </nav>
      <div className="container-fluid d-flex" style={{ height: `calc(100vh - 75px)` }}>
        <div className="row no-gutters flex-grow-1 bg-vseprdarker">
          <div className="col-2 p-0">
            <nav className="nav flex-column mt-4 nav-admin">
              <li className="nav-item">
                <span className="text-white nav-link fw-bold text-uppercase py-2">Access Control</span>
              </li>
              <li className="nav-item">
                <NavLink to="/admin/accesscontrol" className={({ isActive }) => `btn btn-outline-light rounded-0 text-start nav-link py-3 d-flex align-items-center  ${isActive ? 'active bg-light' : ''}`}>
                  <img alt="" src={iconUser} className="me-2" />
                  <span>Users</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <span className="text-white nav-link fw-bold text-uppercase py-2">Integrations</span>
              </li>
              <li className="nav-item">
                <NavLink to="/admin/integrations" className={({ isActive }) => `btn btn-outline-light rounded-0 text-start nav-link py-3 d-flex align-items-center ${isActive ? 'active bg-light' : ''}`}>
                  <img alt="" src={iconLink} className="me-2" />
                  <span>Data and Connections</span>
                </NavLink>
              </li>
            </nav>
          </div>
          <div className="col bg-light p-0" style={{ borderRadius: '0px' }}>
            <div className="main">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const routes = (isSignedIn, role) => [
  {
    element: <Layout />,
    children: [
      { path: '/login', exact: true, name: 'Login', element: !isSignedIn ? <Login /> : <Navigate to="/" /> },
      { path: '/packetbuilderprintable', name: 'PacketBuilderPrintable', element: isSignedIn ? <PacketBuilderPrintable /> : <Navigate to="/login" /> },
      { path: '/playground/dnd', name: 'PlaygroundDnD', element: isSignedIn ? <DndApp /> : <Navigate to="/login" /> },
      { 
        element: <AdminLayout />,
        children: [
          { path: '/', index: true, exact: true, name: 'Dashboard', element: isSignedIn ? <Dashboard /> : <Navigate to="/login" /> },
          { path: '/sources/*', name: 'Sources', element: isSignedIn ? role === ROLES.ADMIN ? <Sources /> : <Navigate to="/" /> : <Navigate to="/login" /> },
          { path: '/analysis/*', name: 'Analysis', element: isSignedIn ? <Analysis /> : <Navigate to="/login" /> },
          { path: '/dataextraction/*', name: 'DataExtraction', element: isSignedIn ? <DataExtraction /> : <Navigate to="/login" /> },
          { path: '/governance/*', name: 'Governance', element: isSignedIn ? <Governance /> : <Navigate to="/login" /> },
        ]
      },
      {
        element: <SuperAdminLayout />,
        children: [
          { path: '/admin/*', name: 'Admin', element: isSignedIn ? role === ROLES.ADMIN ? <Admin /> : <Navigate to="/" /> : <Navigate to="/login" /> },
        ]
      }
    ]
  }
]

function App() {

  const isSignedIn = useSelector(state => state.app.isSignedIn);
  const role = useSelector(state => state.app.role);
  const routing = useRoutes(routes(isSignedIn, role))
  return routing;

}

export default App;