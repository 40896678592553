import { createSlice } from '@reduxjs/toolkit'

export const brainstormSlice = createSlice({
  name: 'brainstorm',
  initialState: {
    prompts: []
  },
  reducers: {
    addPrompt: (state, { payload }) => {
      state.prompts = [...state.prompts, payload];
    },
    setResponsePrompt: (state, {payload: { id, response, session_id }}) => {
      state.prompts = state.prompts.map(prompt => {
        if(prompt.id === id) {
          return {
            ...prompt,
            response,
            session_id
          }
        }
        return prompt;
      })
    },
    removePrompt: (state, { payload: { id } }) => {
      state.prompts = state.prompts.filter(prompt => prompt.id !== id );
    },
    clearPrompts: (state) => {
      state.prompts = [];
    },
    finishedTyping: (state, {payload: { id }}) => {
      state.prompts = state.prompts.map(prompt => {
        if(prompt.id === id) {
          return {
            ...prompt,
            typed: true
          }
        }
        return prompt;
      })
    }
  },
})

export const { addPrompt, setResponsePrompt, removePrompt, clearPrompts, finishedTyping } = brainstormSlice.actions

export default brainstormSlice.reducer